@charset 'UTF-8';
@mixin center {
    display       : table-cell;
    vertical-align: middle;
}
@mixin jdlink {
    display      : block;
    margin-top   : 20px;
    // font-size  : 0.8rem;
    // width      : 50%;
    text-align   : center;
    line-height  : 1.6;
    padding      : 5px 10px;
    color        : #666;
    border       : solid 1px #888;
    background   : #fff;
    border-radius: 3px;
    transition   : 0.3s;

    &:hover {
        color       : #fff;
        background  : rgba(#2C4E83, 0.8);
        border-color: transparent;
    }

    &:hover i.fa-long-arrow-right {
        color: #fff;
    }

    .fa-long-arrow-right {
        transition: 0.3s;
    }

    &:hover .fa-long-arrow-right {
        transform: translate(3px ,0);
    }

    i {
        margin-right: 5px;
    }
}

h3,
h4 {
    line-height: 1.6;
}

.cf:after,
.cf:before {
    content: " ";
    display: table;
}

.cf:after {
    clear: both;
}

.cf {
    *zoom: 1;
}
/*@font-face {
    font-family: 'YuGothR';
    src        : url('../fonts/YuGothR.ttc');
}

* {
    font-family: 'YuGothR', YuGothic, 'Yu Gothic',	sans-serif;
}*/
@font-face {
    font-family: "Yu Gothic";
    src        : local("Yu Gothic Medium");
    font-weight: 100;
}
@font-face {
    font-family: "Yu Gothic";
    src        : local("Yu Gothic Medium");
    font-weight: 200;
}
@font-face {
    font-family: "Yu Gothic";
    src        : local("Yu Gothic Medium");
    font-weight: 300;
}
@font-face {
    font-family: "Yu Gothic";
    src        : local("Yu Gothic Medium");
    font-weight: 400;
}
@font-face {
    font-family: "Yu Gothic";
    src        : local("Yu Gothic Bold");
    font-weight: bold;
}

body {
    // font-family: "Yu Gothic", YuGothic, sans-serif;
}

body {
    font-family: "Yu Gothic", YuGothic, 游ゴシック体, 'Yu Gothic', YuGothic, 'ヒラギノ角ゴシック Pro', 'Hiragino Kaku Gothic Pro', メイリオ, Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
    color      : #333333;
}
/* 動画 */
.mainvisual {
    /*height  : 100%; // 高さを画面いっぱいに指定する。(vhが効かない場合の対策)
    height  : 100vh; // 高さを画面いっぱいに指定する。
    position: relative;
    //横幅がウィンドウズサイズの縦幅よりもはみ出てしまう場合に、左にずらすために指定。
    width   : 100%; // 横幅もいっぱいに。*/
}

#bg-video {
    z-index       : -1;
    width         : 100%;
    height        : auto;
    vertical-align: bottom;
}
/* メイン */
main {
    margin: auto;

    #lead1 {
        background         : {
            color: #2C4E83;
        }
        // background-image   : url("../img/jdbg.jpg");
        background-repeat  : no-repeat;
        background-position: center center;
        background-size    : cover;
        color              : #fff;
        position           : relative;
        overflow           : hidden;

        h2 {
            text-align : center;
            // color      : #0168B7;
            font-weight: 400;
        }

        p {
            text-align: center;
            // color     : #0168B7;
        }
    }

    #lead2 {
        // background-color: #102040;
        h2 {
            text-align: center;
            // font-weight: bold;
            // color: #fff;
        }

        p {
            text-align: center;
            // color: #fff;
        }
    }

    #image {
        h2 {
            text-align: center;
        }

        img {
            width    : 100%;
            max-width: 100%;
            height   : auto;
        }
    }

    #lead3 {
        h2 {
            text-align: center;
        }

        p {
            text-align: center;
        }
    }

    #lead4 {
        h2 {
            text-align: center;
        }

        p {
            text-align: center;
        }
    }

    #jd {
        overflow: hidden;
    }

    #messageimg {
        img {
            width: 100%;
        }

        img.list {
            display   : block;
            margin    : auto;
            width     : 90%;
            margin-top: 50px;
        }
    }

    #tokuchou {
        clear: both;
        // margin-bottom: 60px;
        h2 {
            text-align: center;
        }
    }

    #point,
    #point2,
    #point3 {
        background-color: rgba(165, 190, 217, 0.1);

        h2 {
            text-align: center;
        }

        img {
            width         : 100%;
            vertical-align: bottom;
        }
    }

    #wrapping {
        background-color: rgba(165, 190, 217, 0.1);

        img {
            width         : 100%;
            vertical-align: bottom;
        }
    }

    #kakaku {
        border-top   : 1px solid rgba(0, 0, 0, 0.1);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        h2 {
            text-align   : center;
            margin-bottom: 30px;
        }

        p {
            text-align: center;
        }
    }

    #thankyou h3 {
        text-align: center;
    }

    #kounyu {
        a {
            text-align: center;
            color     : #fff;
            display   : block;
            transition: 0.3s;

            &:hover {
                opacity: 0.6;
            }

            i {
                margin-right: 3%;
            }
        }

        a.rakuten {
            background: #830D1D;
        }

        a.amazon {
            background: #FF9900;
        }

        a.yahoo {
            background: #D00216;
        }
    }
}
/* main */
@media screen and (max-width: 767px) {
    .inner {
        width : 90%;
        margin: auto;
    }

    h2 {
        font-size  : 1.6rem;
        line-height: 1.6;
    }

    h4 {
        font-size  : 1.4rem;
        line-height: 1.6;
    }

    h5 {
        font-size  : 1.4rem;
        line-height: 1.6;
    }

    p {
        line-height: 1.8;
    }

    .pc {
        display: none!important;
    }

    .br-pc {
        display: none;
    }

    #bg-video {
        padding-top: 58px;
    }
    /* メイン */
    main {
        margin: auto;

        #lead1 {
            padding-bottom: 60px;

            h2 {
                font-size  : 1.4rem;
                padding    : 60px 0;
                width      : 90%;
                margin     : auto;
                line-height: 1.4em;
            }

            p {
                font-size: 0.9rem;
                width    : 90%;
                margin   : auto;
            }
        }

        #lead2 {
            margin-bottom: 60px;
            width        : 90%;
            margin       : auto;

            h2 {
                font-size  : 1.6rem;
                padding    : 60px 0 40px;
                line-height: 1.6em;
            }

            p {
                font-size: 0.9rem;
            }
        }

        #image {
            padding-top  : 100px;

            h2 {
                text-align: center;
            }
            margin-bottom: 100px;

            img {
                width        : 100%;
                max-width    : 100%;
                height       : auto;
                margin-bottom: 40px;
            }

            p {
                text-align: center;
                width     : 90%;
                margin    : auto;
            }
        }

        #lead3 {
            margin-bottom: 80px;

            h2 {
                font-size  : 1.6rem;
                padding    : 0 0 40px;
                line-height: 1.4em;
                width      : 90%;
                margin     : auto;
            }

            p {
                font-size : 0.9rem;
                width     : 90%;
                margin    : auto;
                text-align: left;
            }
        }

        #lead4 {
            h2 {
                font-size  : 1.6rem;
                margin     : 40px 0 60px;
                text-align : center;
                line-height: 2.4rem;
            }

            p {
                // font-size : 0.9rem;
                text-align: center;
            }
        }

        #kataguruma {
            background-image   : url("../img/kataguruma.jpg");
            background-repeat  : no-repeat;
            background-position: center center;
            background-size    : cover;
            height             : 110vh;
            padding-top        : 10vw;
            padding-bottom     : 10vw;
            margin-bottom      : 60px;

            p {
                display         : block;
                width           : 80%;
                padding         : 5%;
                margin          : auto;
                background-color: rgba(0, 0, 0, 0.7);
                font-size       : 1.2rem;
                color           : #fff;
                // margin-top: 1s5vw;
            }
        }

        #jd {
            overflow     : hidden;
            margin-bottom: 65px;
            padding-top  : 25px;

            .list {
                margin-bottom: 30px;

                h4 {
                    font-size : 1.2rem;
                    text-align: center;
                    margin    : 30px 0;
                }

                h5 {
                    margin-top: 30px;
                    text-align: center;
                }

                img {
                    display   : block;
                    width     : 80%;
                    margin    : auto;
                    text-align: center;
                    box-shadow: 0 0 20px -5px rgba(0, 0, 0, 0.8);
                }

                i.fa-long-arrow-right {
                    margin-left: 5px;
                    position   : relative;
                }

                a {
                    margin   : auto auto 60px;
                    font-size: 1.1vw;
                    padding  : 15px;
                    font-size: 0.8rem;
                    @include jdlink;
                    width    : 60%;
                }
            }
        }

        #messageimg {
            padding-bottom: 100px;

            h2 {
                text-align    : center;
                padding-bottom: 30px;
                width         : 90%;
                margin        : auto;
            }
        }

        #tokuchou {
            clear         : both;
            padding-bottom: 50px;

            h2 {
                font-size  : 1.6rem;
                margin     : 40px 0;
                width      : 95%;
                margin     : auto;
                line-height: 1.4;
                padding    : 20px 0 40px;
            }

            p {
                font-size: 0.9rem;
                padding  : 20px;
                // width: 80%;
                margin   : auto;
            }
        }

        #point,
        #point2,
        #point3 {
            h4 {
                text-align : center;
                padding-top: 30px;
            }

            p {
                font-size     : 0.9rem;
                text-align    : center;
                padding-bottom: 30px;
                width         : 90%;
                margin        : auto;
                text-align    : left;

                &:last-child {
                    padding: 40px 0;
                }
            }

            .right {
                img {
                    width         : 100%;
                    vertical-align: bottom;
                }
            }
        }

        #detail {
            margin-top: 100px;

            h5 {
                display      : block;
                background   : #f0f0f0;
                width        : 90%;
                margin       : auto;
                text-align   : center;
                padding      : 10px 0;
                border       : 1px solid #999;
                border-bottom: none;
            }

            dl {
                width       : 90%;
                margin      : auto;
                border-top  : 1px solid #999999;
                border-left : 1px solid #999999;
                border-right: 1px solid #999999;
                // overflow    : auto;
                font-size   : 0.9rem;

                &:nth-child(10) dd {
                    border-bottom: 1px solid #999999;
                }

                &:nth-child(10) dt {
                    border-bottom: 1px solid #999999;
                }

                dt {
                    font-size    : 0.8rem;
                    width        : 20%;
                    height       : 220px;
                    padding      : 10px;
                    background   : #f0f0f0;
                    border-right : 1px solid #999;
                    border-bottom: 1px solid #999;
                    line-height  : 1.4;
                }

                dd {
                    font-size    : 0.8rem;
                    padding      : 0 5px 5px calc(20% + 30px);
                    margin       : -230px 0 0;
                    min-height   : 100px;
                    border-bottom: 1px #999 solid;
                    line-height  : 1.6;
                }

                dt.row-1 {
                    height: 45px;
                }

                dd.row-1 {
                    margin    : -55px 0 0;
                    min-height: 45px;
                }
            }
        }

        #wrapping {
            .lead {
                background-color: #fff;
                padding         : 150px 0 50px;

                h2 {
                    font-size    : 30px;
                    margin-bottom: 30px;
                    text-align   : center;
                }

                p {
                    font-size: 16px;
                }

                h3,
                p {
                    text-align: center;
                }
            }

            .right {
                padding-bottom: 80px;

                h4 {
                    font-size : 1.6rem;
                    text-align: center;
                    padding   : 80px 0 40px;
                }

                p {
                    font-size: 0.9rem;
                    // text-align: center;
                    width    : 90%;
                    margin   : auto;
                }
            }
        }

        #kakaku {
            padding: 50px 0;

            h2 {
                padding-bottom: 40px;
                font-size     : 1.4rem;
                width         : 90%;
                margin        : auto;
                line-height   : 1.4;
            }

            p {
                font-size     : 0.9rem;
                width         : 90%;
                margin        : auto;
                padding-bottom: 10px;
                text-align    : left;
            }

            img {
                margin-top: 30px;
                width     : 100%;
            }
        }

        #thankyou {
            padding: 50px 0;

            h3 {
                padding-bottom: 50px;
                font-size     : 1.4rem;
                line-height   : 1.6em;

                &:last-child {
                    padding-bottom: 0;
                }
            }
        }

        #kounyu {
            width  : 80%;
            margin : auto;
            padding: 50px 0 10px;

            a {
                width  : 90%;
                padding: 20px 0;
                margin : auto auto 30px;
            }
        }
    }
}
@media screen and (min-width: 768px) {
    .br-sp {
        display: none;
    }

    .sp {
        display: none!important;
    }

    .slideheader {
        // display: none;
    }

    h2 {
        font-size  : 3rem;
        line-height: 1.6;
    }

    h3 {
        font-size  : 2.4rem;
        line-height: 1.6;
    }

    h4 {
        font-size: 1.8rem;
    }

    h5 {
        font-size  : 1.8rem;
        line-height: 1.6;
    }

    #bg-video {
        margin-top: px;
    }

    .lead {
        p {
            font-size  : 1.6rem;
            line-height: 1.8;
        }
    }

    #lead1 {
        padding: 200px 0 150px;

        h2 {
            margin-bottom: 80px;
        }
    }

    #lead2 {
        padding: 250px 0 120px;
    }

    #lead3 {
        padding: 300px 0 50px;

        h2 {
            margin-bottom: 80px;
        }

        p {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    #lead4 {
        padding-top   : 100px;
        padding-bottom: 50px;

        h2 {
            margin-top: 300px;
        }
    }

    #kataguruma {
        background-image     : url("../img/kataguruma.jpg");
        background-repeat    : no-repeat;
        background-position  : center center;
        background-size      : cover;
        height               : 100vh;
        background-attachment: fixed;

        p {
            display         : block;
            width           : 50%;
            position        : relative;
            left            : 45%;
            padding         : 3%;
            top             : 3vw;
            box-sizing      : border-box;
            background-color: rgba(0, 0, 0, 0.7);
            font-size       : 1.8vw;
            line-height     : 1.6;
            color           : #fff;
        }
    }

    #image {
        padding-top  : 200px;
        margin-bottom: 60px;

        img {
            width    : 100%;
            max-width: 100%;
            height   : auto;
        }

        p {
            font-size  : 1.6rem;
            line-height: 1.8;
            text-align : center;
        }
    }

    #point,
    #point2,
    #point3 {
        .pointinner {
            display: table;
            margin : auto;
        }

        h4 {
            margin: -50px 0 30px;
        }

        p {
            font-size  : 1.2rem;
            line-height: 2rem;
        }

        .left {
            display       : table-cell;
            vertical-align: middle;
            width         : 50%;
        }

        .right {
            display       : table-cell;
            vertical-align: middle;
            width         : 50%;

            img {
                width         : 100%;
                vertical-align: bottom;
            }
        }
    }

    #point {
        .left {
            padding: 0 3%;
            height : 500px;
        }

        .right {
            background-image   : url("../img/DSC_0357.JPG");
            background-size    : cover;
            background-position: center center;
        }
    }

    #point2 {
        .right {
            padding: 0 3%;
            height : 500px;
        }

        .left {
            background-image   : url("../img/DSC_0348.jpg");
            background-size    : cover;
            background-position: center center;
        }
    }

    #point3 {
        .left {
            padding: 0 3%;
            height : 500px;
        }

        .right {
            background-image   : url("../img/DSC_0354.JPG");
            background-size    : cover;
            background-position: center center;
        }
    }

    #detail {
        margin-top: 100px;

        h5 {
            display      : block;
            background   : #f0f0f0;
            width        : 100%;
            margin       : auto;
            text-align   : center;
            padding      : 10px 0;
            border       : 1px solid #999;
            border-bottom: none;
        }

        dl {
            width       : 100%;
            margin      : auto;
            border-top  : 1px solid #999999;
            border-left : 1px solid #999999;
            border-right: 1px solid #999999;
            // overflow    : auto;
            font-size   : 0.9rem;

            dt {
                width       : 20%;
                height      : 50px;
                padding     : 10px;
                background  : #f0f0f0;
                border-right: 1px solid #999;
            }

            dd {
                padding      : 0 0 5px calc(20% + 30px);
                margin       : -60px 0 0;
                min-height   : 50px;
                border-bottom: 1px #999 solid;
                line-height  : 1.6;
            }

            dt.row-1 {
                height: 30px;
            }

            dd.row-1 {
                margin    : -40px 0 0;
                min-height: 30px;
            }

            dt.row-4 {
                height: 100px;
            }

            dd.row-4 {
                margin    : -110px 0 0;
                min-height: 100px;
            }
        }
    }

    #wrapping {
        // clear: both;
        .lead {
            background-color: #fff;
            padding         : 200px 0 250px;

            img {
                width         : 100%;
                padding-bottom: 0;
            }

            h2 {
                font-size : 3rem;
                text-align: center;
                margin    : 30px 0;
            }

            p {
                text-align   : center;
                font-size    : 1.6rem;
                line-height  : 2rem;
                margin-bottom: 20px;

                &:last-child {
                    margin-bottom: 0;
                }

                &.attn {
                    font-size: 1.1rem;
                }
            }
        }

        .wrappinginner {
            display: table;
            margin : auto;

            .left {
                display       : table-cell;
                vertical-align: middle;
                width         : 50%;

                img {
                    width    : 100%;
                    max-width: 100%;
                    height   : auto;
                }
            }

            .right {
                display       : table-cell;
                vertical-align: middle;
                padding       : 0 5%;
                width         : 50%;

                h4 {
                    margin: 10px 0 30px;
                }

                p {
                    font-size  : 1.2rem;
                    line-height: 1.6;
                }
            }
        }
    }

    #jd {
        padding-top: 100px;

        .list {
            margin-top: 30px;
            background: rgba(165, 190, 217, 0.1);

            h4 {
                font-size : 1.2rem;
                margin    : 2vw 0;
                text-align: center;
            }

            h5 {
                // padding-top: 10px;
                width     : 90%;
                margin    : auto;
                text-align: center;
            }

            a {
                margin   : auto;
                font-size: 1.1vw;
                @include jdlink;
                width    : 50%;
            }
        }

        .jdlist_p {
            width: 30%;
            @include center;

            img {
                width  : 80%;
                padding: 10%;
            }
        }

        .jdlist_t {
            width: 70%;
            @include center;

            img {
                width  : 80%;
                padding: 10%;
            }
        }
    }

    #messageimg {
        padding-top: 200px;

        h2 {
            text-align    : center;
            padding-bottom: 50px;
        }
    }

    #tokuchou {
        padding: 300px 0 50px;

        h2 {
            padding-bottom: 60px;
        }

        p {
            font-size    : 1.6rem;
            line-height  : 1.5em;
            margin-bottom: 30px;
            text-align   : center;
        }
    }

    #kakaku {
        padding: 100px 0;

        h2 {
            width         : 90%;
            margin        : auto;
            padding-bottom: 40px;
        }

        p {
            font-size     : 1.6rem;
            line-height   : 2em;
            width         : 90%;
            margin        : auto;
            padding-bottom: 10px;
        }

        img {
            padding-top: 50px;
            display    : block;
            width      : 80%;
            margin     : auto;
        }
    }

    #thankyou {
        padding: 100px 0;

        h3 {
            padding-bottom: 60px;
        }
    }

    #kounyu {
        width  : 80%;
        margin : auto;
        padding: 50px 0 100px;

        a {
            width       : 30%;
            // width     : 35%;
            float       : left;
            padding     : 20px 10px;
            box-sizing  : border-box;
            margin-right: 5%;
            // margin    : 0 10%;
            &:last-child {
                margin-right: 0;
                // margin-left: 0;
            }
        }
    }
}
@media screen and (min-width: 1024px) {
    #jd {
        padding-top: 100px;
    }
}
@media only screen and (max-device-width:700px) and (orientation:landscape) {
    main #jd .jdlist img {
        display: block;
        width  : 50%;
        margin : 0 auto;
    }
}
